<template>
    <div class="az-body">
        <div class="az-error-wrapper">
            <h1 class="tx-success">Success</h1>
            <h3>You have successfully paid the amount</h3>
            <h6>Please back to wallet, the equivalent amount of GRAM will be transferred to your wallet</h6>
            <p>You'll be redirected to the wallet page in {{ timerCount }} sec or <a href="javascript:void(0)" @click="toOrg()">Click Here</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "SuccessUrl",
    data() {
        return {
            timerCount: 5
        }
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                        if(this.timerCount === 0){
                            this.$router.push({name: 'myorg'});
                        }
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    methods: {
        toOrg(){
            this.$router.push({name: 'myorg'});
        }
    }
}
</script>

<style scoped>

</style>